import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'bulkUserId', 'bulkScientist', // Scientist info
    'bulkProductProviderId', 'bulkVendor', // Vendor info
    'bulkAccountingCode', 'bulkProject', 'bulkCategory', 'bulkLocation', // Lab optional fields
    'bulkNotes', // Notes
    'requestItemSubForm' // Request item sub form
  ]

  setAllBulkFields(event) {
    event.preventDefault()

    const bulkNotes = this.bulkNotesTarget.value
    const bulkUserId = this.bulkUserIdTarget.value
    const bulkScientist = this.bulkScientistTarget.value
    const bulkProductProviderId = this.bulkProductProviderIdTarget.value
    const bulkVendor = this.bulkVendorTarget.value

    this.requestItemSubFormTargets.forEach(subForm => {
      if (!!bulkUserId && !!bulkScientist) {
        const scientistSelectize = subForm.querySelector("[name$='[user_id]']").selectize
        this.#setSelectizeValue(scientistSelectize, bulkUserId, bulkScientist)

        subForm.querySelector("[name$='[scientist]']").value = bulkScientist
        subForm.querySelector("[name$='[user_id]']").value = bulkUserId
      }

      if (!!bulkProductProviderId && !!bulkVendor) {
        const vendorSelectize = subForm.querySelector("[name$='[vendor_search]']").selectize
        this.#setSelectizeValue(vendorSelectize, bulkProductProviderId, bulkVendor)

        subForm.querySelector("[name$='[vendor]']").value = bulkVendor
        subForm.querySelector("[name$='[product_provider_id]']").value = bulkProductProviderId
      }

      if (this.hasBulkAccountingCodeTarget) {
        const accountingCode = this.bulkAccountingCodeTarget.value
        if (!!accountingCode) subForm.querySelector("[name$='[accounting_code]']").value = accountingCode
      }

      if (this.hasBulkProjectTarget) {
        const project = this.bulkProjectTarget.value
        if (!!project) subForm.querySelector("[name$='[project]']").value = project
      }

      if (this.hasBulkCategoryTarget) {
        const category = this.bulkCategoryTarget.value
        if (!!category) subForm.querySelector("[name$='[category]']").value = category
      }

      if (this.hasBulkLocationTarget) {
        const location = this.bulkLocationTarget.value
        if (!!location) subForm.querySelector("[name$='[location]']").value = location
      }

      if (!!bulkNotes) {
        const notesField = subForm.querySelector("[name$='[notes]']")
        if (!!notesField) notesField.value = notesField.value ? `${notesField.value}\n${bulkNotes}` : bulkNotes
      }
    })

    this.#clearAllFields()
  }

  #clearAllFields() {
    // Clear scientist info
    this.bulkUserIdTarget.value = ''
    this.bulkScientistTarget.value = ''
    const scientistSelectize = this.element.querySelector("[name$='[user_id]']").selectize
    this.#setSelectizeValue(scientistSelectize, '', '')

    // Clear vendor info
    this.bulkProductProviderIdTarget.value = ''
    this.bulkVendorTarget.value = ''
    const vendorSelectize = this.element.querySelector("[name$='[vendor_search]']").selectize
    this.#setSelectizeValue(vendorSelectize, '', '')

    // Clear notes
    this.bulkNotesTarget.value = ''

    // Clear lab optional fields
    if (this.hasBulkAccountingCodeTarget) this.bulkAccountingCodeTarget.value = ''
    if (this.hasBulkProjectTarget) this.bulkProjectTarget.value = ''
    if (this.hasBulkCategoryTarget) this.bulkCategoryTarget.value = ''
    if (this.hasBulkLocationTarget) this.bulkLocationTarget.value = ''
  }

  #setSelectizeValue(selectizeField, value, textboxValue) {
    selectizeField.setValue(value, false)
    selectizeField.setTextboxValue(textboxValue)
    selectizeField.trigger('change')
  }
}
