import { Controller } from '@hotwired/stimulus'
import { forEach, trim } from 'lodash'

export default class extends Controller {
  static targets = ['requestItemRow', 'hlEquivalenceIndicator', 'refresh']

  connect() {
    $(document).on('change', 'input[id^="create_from_"][type="checkbox"]', this.refreshSelectedItems.bind(this))
  }

  refreshSelectedItems(_event) {
    const editRequestItemsButton = $('#js-bulk-edit-request-items')
    const fullCopyRequestItemsButton = $('#js-copy-full-request-items')
    const deleteRequestItemsButton = $('.btn-delete-items')
    const emailRequestItemsButton = $('.btn-email-items')
    const emailApprovalButton = $('.btn-email-approval')
    const requestItemsSelected = $('input:checked[id^="create_from_"][type="checkbox"]')
    const shoppingCart = $('#shopping_cart')
    const requestItemsIds = []
    let scientistPopulated = false

    // Clear the scientist info
    $('#email-selected-scientist').removeData().removeAttr('data-scientist')

    requestItemsSelected.each(function(index, checkbox) {
      requestItemsIds.push($(checkbox).val())

      if (!scientistPopulated) {
        const addToCartEl = $(checkbox).closest('.add-to-cart')
        const scientistUser = addToCartEl?.data('scientistUser') || {}

        if (Object.keys(scientistUser).length) {
          $('#email-selected-scientist').attr('data-scientist', JSON.stringify(scientistUser))
          scientistPopulated = true
        }
      }
    })

    // Show/hide delete and email items buttons
    requestItemsSelected.length > 0 ? editRequestItemsButton.show() : editRequestItemsButton.hide()
    requestItemsSelected.length > 0 ? fullCopyRequestItemsButton.show() : fullCopyRequestItemsButton.hide()
    requestItemsSelected.length > 0 ? deleteRequestItemsButton.show() : deleteRequestItemsButton.hide()
    requestItemsSelected.length > 0 ? emailRequestItemsButton.show() : emailRequestItemsButton.hide()
    requestItemsSelected.length > 0 ? emailApprovalButton.show() : emailApprovalButton.hide()

    // Adjust shopping cart
    shoppingCart.val(requestItemsIds.join(','))

    // Adjust bulk delete modal action based on selected request items
    $('#delete-request-items-form').attr('action', '/request_items/' + requestItemsIds.join(','))

    // Adjust email all form based on selected request items
    $('#email_selected_request_ids').val(requestItemsIds.join(','))

    // Adjust email approval data-url
    let button = $('#email-approval-button')
    let params = '?request_ids=' + requestItemsIds.join(',') + '&' + button.attr('data-params')
    button.attr('data-url', '/request_items/email_approval' + params )

    // Adjust edit request items button frame url based on selected request items
    const editRequestItemsTargetUrl =
      requestItemsSelected.length > 0
        ? `${editRequestItemsButton.data().baseTargetUrl}?request_items_ids=${requestItemsIds.join(',')}`
        : editRequestItemsButton.data().baseTargetUrl

    editRequestItemsButton.on('click', (event) => {
      event.preventDefault()
      location.href = editRequestItemsTargetUrl
    })
    
    // Add copy handler
    fullCopyRequestItemsButton.on('click', (event) => this.handleFullCopyRequestItems(event, requestItemsIds))

    // Adjust selected count message
    $('#selected-count').html(`${requestItemsSelected.length} selected ${requestItemsSelected.length === 1 ? 'item' : 'items'}`)
  }

  refreshTargetConnected(_element) {
    this.refreshSelectedItems(undefined)
  }

  requestItemRowTargetConnected(element) {
    // Re-initialize tooltips
    $(element).find('[data-toggle="tooltip"]')?.tooltip()
  }

  requestItemRowTargetDisconnected(_element) {
    this.refreshSelectedItems(undefined)
  }

  disconnect() {
    $(document).off('change', 'input[id^="create_from_"][type="checkbox"]')
    $('#js-bulk-edit-request-items').off('click')
    $('#js-copy-full-request-items').off('click')
  }

  get editRequestItemModal() {
    return document.getElementById('edit_request_item_modal')
  }

  get deleteRequestItemModal() {
    return document.getElementById('delete_request_item_modal')
  }

  get emailApprovalModal() {
    return document.getElementById('request_item_email_approval_modal')
  }

  toggleRequestFormDisplay(event) {
    event.preventDefault()
    const formCard = $('.lab-staff-form-card')
    formCard.toggleClass('hide')
    event.target.innerHTML = formCard.hasClass('hide') ? 'Create New Request' : 'Close Form'
  }

  openEditModal(event) {
    const frame = this.editRequestItemModal
    if (!frame) return

    frame.src = event.target.dataset.url
    frame.reload()
  }

  openDeleteModal(event) {
    const frame = this.deleteRequestItemModal
    if (!frame) return

    frame.src = event.target.dataset.url
    frame.reload()
  }

  openEmailApprovalModal(event) {
    const frame = this.emailApprovalModal
    if (!frame) return

    frame.src = event.currentTarget.dataset.url
    frame.reload()
  }

  hlEquivalenceIndicatorTargetConnected(element) {
    const tooltipEl = $(element)
    tooltipEl?.tooltip({ trigger: 'hover' })
    tooltipEl?.click(function () { tooltipEl.tooltip('hide') })
  }

  async handleFullCopyRequestItems(event, requestItemsIds) {
    const requestItemRowOutputMap = {
      'date': 'Date:',
      'description': 'Description:',
      'quantity': 'QTY:',
      'catalog-number': 'Catalog #:',
      'price': 'Price/Unit:',
      'vendor': 'Vendor:',
      'scientist': 'Scientist:',
      'accounting-code': 'Acct. Code:'
    }

    const requestItemNotesRowOutputMap = {
      'need-by': '',
      'notes': ''
    }

    let contentToCopy = ''

    requestItemsIds.forEach((requestItemId) => {
      const requestItemRow = document.querySelector(`#request_item_${requestItemId}`)
      const requestItemNotesRow = document.querySelector(`#request_item_notes_${requestItemId}`)

      if (!!requestItemRow) {
        forEach(requestItemRowOutputMap, (outputLabel, selector) => {
          const lookupXPath = `.//td[contains(@class, '${selector}')]`
          const element = this.#lookupElementByXPath(lookupXPath, requestItemRow)

          if (!!element)
            contentToCopy += trim(`${outputLabel} ${element.innerText}\n`, ' ')
        })
      }

      if (!!requestItemNotesRow) {
        forEach(requestItemNotesRowOutputMap, (outputLabel, selector) => {
          const lookupXPath = `.//td[contains(@class, '${selector}')]`
          const element = this.#lookupElementByXPath(lookupXPath, requestItemNotesRow)

          if (!!element)
            contentToCopy += trim(`${element.innerText}\n`, ' ')
        })
      }

      if (!!requestItemRow && !!requestItemNotesRow)
        contentToCopy += '\n'
    })

    if (!!contentToCopy && contentToCopy.length) {
      try {
        await navigator.clipboard.writeText(contentToCopy)
      } catch (err) {
        console.error('Failed to copy:', err)
      }
    }
  }

  #lookupElementByXPath(xpath, context) {
    return document.evaluate(xpath, context, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)?.singleNodeValue
  }
}
